



















































































import { getRoleLabel } from "@/helpers";
import { User, CoreUserGetters } from "@/spect8-core-vue/src/types";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { PINS_NAMESPACE } from ".";
import { Broadcast, BroadcastGetters } from "../Broadcast/broadcast/types";
import ConfirmDialog from "../ConfirmDialog.vue";
import { Pin, PinType } from "./types";

@Component({
  components: { ConfirmDialog },
})
export default class PinsList extends Vue {
  @Getter(BroadcastGetters.Broadcasts)
  readonly broadcasts!: Broadcast[];
  @Getter(BroadcastGetters.Loading)
  readonly broadcastsLoading!: boolean;
  @Getter(`${PINS_NAMESPACE}/pins`)
  readonly pins!: Pin[];
  @Getter(CoreUserGetters.User)
  readonly user!: User | null;
  @Action(`${PINS_NAMESPACE}/fetchPins`)
  fetchPins!: () => Promise<void>;
  @Action(`${PINS_NAMESPACE}/setEdit`)
  editPin!: (item: Pin) => void;
  @Action(`${PINS_NAMESPACE}/deletePin`)
  deletePinAction!: (pin: Pin) => Promise<void>;

  confirmDelete = false;
  toBeDeleted: Pin | null = null;

  search = "";

  headers = [
    {
      text: this.$t("pins.colPin"),
      align: "start",
      sortable: false,
      value: "key",
    },
    { text: this.$t("pins.colRole"), value: "role" },
    { text: this.$t("pins.colBroadcast"), value: "broadcasts" },
    { text: this.$t("pins.expiresAt"), value: "expiresAt" },
    {
      text: this.$t("pins.colActions"),
      value: "actions",
      sortable: false,
      align: "center",
      cellClass: "text-no-wrap",
    },
  ];

  getRoleLabel = getRoleLabel;

  mounted() {
    this.fetchPins();
  }

  expiresAtState(expiresAt: number): string {
    const now = Date.now();
    const expiresAtString = this.$d(expiresAt, "DateTimeShort");
    if (expiresAt > now) {
      return expiresAtString;
    }
    return `${this.$t("pins.expired")} (${expiresAtString})`;
  }

  handleDelete(p: Pin) {
    this.toBeDeleted = p;
    this.confirmDelete = true;
  }

  deletePin(p: Pin | null) {
    if (!p) return;
    this.confirmDelete = false;
    this.toBeDeleted = null;
    this.deletePinAction(p);
  }

  pinIsTenantWide(pin: Pin): boolean {
    return pin.pin_type === PinType.TENANT;
  }

  actionsDisabled(pinBroadcasts: Broadcast[]): boolean {
    return (
      !!this.user &&
      this.user.isOnlyBroadcastModerator() &&
      (pinBroadcasts.length === 0 ||
        pinBroadcasts.some((pbc) =>
          this.broadcasts.some((b) => b.id !== pbc.id)
        ))
    );
  }
}
