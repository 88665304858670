var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.broadcastsLoading)?_c('v-row',{staticClass:"page-row",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"items":_vm.pins,"headers":_vm.headers,"search":_vm.search,"footer-props":{
        'items-per-page-text': _vm.$t('dataTable.rowsPerPage'),
      }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"table-header-search"},[_c('p',{staticClass:"text-subtitle-1 font-weight-semi-bold mb-0"},[_vm._v(" "+_vm._s(_vm.$t("pins.listTitle"))+" ("+_vm._s(_vm.pins.length)+") ")]),_c('v-spacer'),_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"prepend-icon":"mdi-magnify","placeholder":_vm.$t('forms.search'),"single-line":"","hide-details":"","color":"primary"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.role",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRoleLabel(item.role))+" ")]}},{key:"item.broadcasts",fn:function(ref){
      var item = ref.item;
return [_c('td',{staticClass:"tdAlign"},[_vm._v(" "+_vm._s(!_vm.pinIsTenantWide(item) ? item.broadcasts .map(function (broadcast) { return broadcast.broadcastName; }) .join(", ") : _vm.$t("pins.allBroadcasts"))+" ")])]}},{key:"item.expiresAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.expiresAtState(item.expiresAt))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","disabled":_vm.actionsDisabled(item.broadcasts)},on:{"click":function($event){return _vm.editPin(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.actionsDisabled(item.broadcasts)},on:{"click":function($event){return _vm.handleDelete(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1),_c('ConfirmDialog',{attrs:{"dialog":_vm.confirmDelete,"title":_vm.$t('pins.dialogDeleteTitle'),"confirmText":_vm.$t('Delete'),"confirmColor":"error"},on:{"cancelled":function($event){_vm.confirmDelete = false},"confirmed":function($event){return _vm.deletePin(_vm.toBeDeleted)}}},[_vm._v(" PIN: "+_vm._s(_vm.toBeDeleted ? _vm.toBeDeleted.key : "")+" ")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }