




























































































import { debounce, getRoleSelectionItems } from "@/helpers";
import { YEAR_IN_MS } from "@/helpers/consts";
import { PinsResponse } from "@/services/api/anonu.types";
import { VForm } from "@/types";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { PINS_NAMESPACE } from ".";
import { Broadcast, BroadcastGetters } from "../Broadcast/broadcast/types";
import { Pin, PinActionPayload, PinType } from "./types";
import DateTimePicker from "@/components/DateTimePicker.vue";
import { Role, User, CoreUserGetters } from "@/spect8-core-vue/src/types";

@Component({
  components: {
    DateTimePicker,
  },
})
export default class PinsForm extends Vue {
  @Getter(BroadcastGetters.Broadcasts)
  readonly broadcasts!: Broadcast[];
  @Getter(`${PINS_NAMESPACE}/edit`)
  readonly editPin!: Pin | undefined;
  @Getter(CoreUserGetters.User)
  readonly user!: User | null;

  @Action(`${PINS_NAMESPACE}/addPin`)
  addPin!: (pin: PinActionPayload) => Promise<PinsResponse>;
  @Action(`${PINS_NAMESPACE}/updatePin`)
  updatePin!: (pin: PinActionPayload) => Promise<PinsResponse>;
  @Action(`${PINS_NAMESPACE}/clearEdit`)
  clearEdit!: () => Promise<void>;
  @Action(`${PINS_NAMESPACE}/isUnique`)
  isUnique!: (pin: string) => Promise<boolean | undefined>;

  min = 6;
  max = 12;
  pin = "";
  roleItems = getRoleSelectionItems([Role.USER, Role.MODERATOR]);
  role = this.roleItems[0].value;
  activeAlways = false;
  selectedBroadcasts: string[] = [];
  valid = false;
  pinIsUnique = true;
  expiresAt = 0;

  @Watch("activeAlways")
  onAA() {
    this.validateField();
  }
  @Watch("editPin")
  onEditChange() {
    this.initialData();
  }

  isUniquePinDebounce = debounce((pin: string) => this.isUniquePin(pin), 600);

  isUniquePin(pin: string) {
    this.isUnique(pin).then((res) => {
      this.pinIsUnique = res ?? false;
      this.$refs.pinTextField.validate();
    });
  }

  @Watch("pin")
  onPinChange(pin: string) {
    if (pin.length && this.editPin && this.editPin.key === pin) {
      this.pinIsUnique = true;
      return;
    }

    if (pin.length >= this.min && pin.length <= this.max) {
      this.isUniquePinDebounce(pin);
    }
  }

  $refs!: {
    form: VForm;
    pinTextField: VForm;
  };

  get minExpiresAtDate(): number {
    const now = Date.now();
    if (this.expiresAt !== 0 && this.expiresAt < now) {
      return this.expiresAt;
    }
    return now;
  }

  get pinRules() {
    return [
      (v: string) =>
        ((v || "").length <= this.max && (v || "").length >= this.min) ||
        this.$t("pins.pinErrorCharLimit", { min: this.min, max: this.max }),

      (v: string) =>
        (v || "").match(/[^a-zA-Z0-9-_]/) === null ||
        this.$t("pins.pinErrorSymbols"),

      () => this.pinIsUnique || this.$t("pins.pinErrorUnique"),
    ];
  }

  get bcRules() {
    return [(v: string) => this.activeAlways || (v || []).length > 0];
  }

  get userIsBroadcastMod(): boolean {
    if (this.user) {
      return this.user.isOnlyBroadcastModerator();
    }
    return true;
  }

  async submit() {
    if (!this.$refs.form?.validate() || this.expiresAt === 0) return;
    const p: PinActionPayload = {
      id: this.editPin?.id,
      key: this.pin,
      role: this.role,
      broadcasts: this.selectedBroadcasts,
      pinType: this.activeAlways ? PinType.TENANT : PinType.BROADCASTS,
      expiresAt: this.expiresAt,
    };

    if (this.editPin) {
      await this.updatePin(p);
    } else {
      await this.addPin(p);
    }

    this.discard();
  }

  validateField() {
    this.$refs.form?.validate();
  }

  initialData() {
    this.$refs.form?.resetValidation();
    if (this.editPin) {
      this.selectedBroadcasts = this.editPin.broadcasts?.map((b) => b.id);
      this.pin = this.editPin.key;
      this.role = this.editPin.role;
      this.activeAlways = this.editPin.pin_type === PinType.TENANT;
      this.expiresAt = this.editPin.expiresAt;
    } else {
      this.selectedBroadcasts = [];
      this.pin = "";
      this.role = Role.USER;
      this.activeAlways = false;
      this.expiresAt = Date.now() + YEAR_IN_MS;
    }
  }

  async discard() {
    if (this.editPin) await this.clearEdit();
    this.initialData();
  }

  mounted(): void {
    this.initialData();
  }

  beforeDestroy() {
    this.discard();
  }
}
