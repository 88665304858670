import { Role } from "@/spect8-core-vue/src/types";
import { Broadcast } from "../Broadcast/broadcast/types";

export interface PinsState {
  loaded: boolean;
  pins: Pin[];
  edit?: Pin;
}

export interface Pin {
  id: string;
  key: string;
  role: Role;
  broadcasts: Broadcast[];
  pin_type: PinType;
  expiresAt: number;
}

export enum PinType {
  TENANT = "tenant",
  BROADCASTS = "broadcasts",
}

export interface PinActionPayload {
  id?: string;
  key: string;
  role: Role;
  broadcasts: string[];
  pinType: PinType;
  expiresAt: number;
}
